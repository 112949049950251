import { useContext, useState } from "react";
import { Tabs } from "rsuite";
import { useData } from "@microsoft/teamsfx-react";
import * as axios from "axios";
import Recent from "./Recent";
import Contacts from "./Contacts";
import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import { TeamsFxContext } from "./Context";
import config from "./lib/config";
import "rsuite/Tabs/styles/index.css";
import { app, authentication } from "@microsoft/teams-js";

const SideBar = (props) => {
  const [needConsent, setNeedConsent] = useState(false);
  const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
  const [respdata, setRespData] = useState({
    recentcontacts: "",
    personalcontact: "",
  });

  async function callRecentContacts(teamsUserCredential: TeamsUserCredential) {
    try {
      const apiBaseUrl = config.apiEndpoint + "/api/";
      // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
      const apiClient = createApiClient(
        apiBaseUrl,
        new BearerTokenAuthProvider(
          async () => (await teamsUserCredential.getToken(""))!.token
        )
      );
      const response = await apiClient.get("getUserPeople");
      return response.data;
    } catch (err: unknown) {
      setNeedConsent(true);
      if (axios.default.isAxiosError(err)) {
        let funcErrorMsg = "";

        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ": " + err.response.data.error;
        }

        throw new Error(funcErrorMsg);
      }
      throw err;
    }
  }

  async function callPersonalContacts(
    teamsUserCredential: TeamsUserCredential
  ) {
    try {
      const apiBaseUrl = config.apiEndpoint + "/api/";
      // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
      const apiClient = createApiClient(
        apiBaseUrl,
        new BearerTokenAuthProvider(
          async () => (await teamsUserCredential.getToken(""))!.token
        )
      );
      const response = await apiClient.get("getUserContacts");
      return response.data;
    } catch (err: unknown) {
      setNeedConsent(true);
      if (axios.default.isAxiosError(err)) {
        let funcErrorMsg = "";

        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ": " + err.response.data.error;
        }

        throw new Error(funcErrorMsg);
      }
      throw err;
    }
  }

  const openPopup = async () => {
    app.initialize();
    try {
      const result = await authentication.authenticate({
        url: window.location.origin + "/login",
        width: 600,
        height: 500,
      });
      console.log("Authentication successful:", result);
    } catch (error) {
      console.error("Authentication failed:", error);
    }
  };

  const { loading, data, error, reload } = useData(async () => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    if (needConsent) {
      await teamsUserCredential!.login([
        "User.Read",
        "Contact.Read",
        "People.Read",
      ]);
      setNeedConsent(false);
    }
    try {
      const recentcontacts = await callRecentContacts(teamsUserCredential);
      const personalcontact = await callPersonalContacts(teamsUserCredential);

      setRespData({
        recentcontacts: recentcontacts.graphClientMessage.value,
        personalcontact: personalcontact.graphClientMessage.value,
      });
      return;
    } catch (error: any) {
      if (error.message.includes("The application may not be authorized.")) {
        setNeedConsent(true);
      }
    }
  });

  return (
    <>
      <h4 className="sideBarTitle">Contacts</h4>
      {needConsent && (
        <>
          <button
            className="mt-3 btn submit-btn reload ms-2"
            onClick={openPopup}
          >
            Authorize Now
          </button>
        </>
      )}
      {!needConsent && (
        <Tabs defaultActiveKey="1">
          <Tabs.Tab eventKey="1" title="Teams">
            {respdata.recentcontacts.length > 0 && (
              <Recent
                recentcontacts={respdata.recentcontacts}
                accountemail={props.accountemail}
              />
            )}
          </Tabs.Tab>
          <Tabs.Tab eventKey="2" title="Personal">
            {respdata.personalcontact.length > 0 && (
              <Contacts
                personalcontacts={respdata.personalcontact}
                accountemail={props.accountemail}
              />
            )}
          </Tabs.Tab>
        </Tabs>
      )}
    </>
  );
};

export default SideBar;
