import { useEffect } from "react";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "./lib/config";
import Main from "./Main";
import { TeamsFxContext } from "./Context";
import { app } from "@microsoft/teams-js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";

const App = () => {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });

  useEffect(() => {
    loading &&
      app.initialize().then(() => {
        // Hide the loading indicator.
        app.notifySuccess();
      });
  }, [loading]);

  return (
    <TeamsFxContext.Provider value={{ teamsUserCredential }}>
      <Router>
        <Routes>
          <Route path="/" element={!loading && <Main />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </TeamsFxContext.Provider>
  );
};

export default App;
